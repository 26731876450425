import React, { useCallback, useRef, useState, useEffect } from "react";
import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import ExpandingButton from "@/components/Buttons/ExpandingButton";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import { motion, AnimatePresence } from "framer-motion";
import useLocale from "@/hooks/useLocale";
import useSpecialisms from "@/hooks/useSpecialisms";
import image from "@/assets/about/specialism-about-img.png";
import vector from "@/assets/about/vector-specialism.png";
import localizeContent from "@/functions/localizeContent";
import localizePath from "@/functions/localizePath";

export default function SpecialismsStack({ path }) {
  const locale = useLocale();
  const stack = useSpecialisms();
  const sectionRef = useRef(null);
  const [activeStack, setActiveStack] = useState(stack[0]);
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeAnalytics = useCallback(
    (stack) => {
      if (stack !== activeStack) {
        setActiveStack(stack);
      }
    },
    [activeStack],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust threshold as needed, make it smaller for more sensitive detection
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(sectionRef.current);

    // Cleanup function
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
      // setIsVisible(!mobileDevice); // Hide motion on mobile devices
    };

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <section ref={sectionRef}>
        <div className="relative">
          <img
            src={vector ?? ""}
            alt="vector"
            className="absolute w-fit top-[-240px] right-0 z-1 hidden sm:block "
          />
        </div>
        <div className="px-4 md:px-[50px] xl:px-[88px] sm:pt-[40px]">
          <div className="border-t border-gray-300 my-4" />
        </div>
        <div className="block lg:grid grid-cols-2 gap-4 relative z-40 container">
          <div className="sm:py-10">
            <div>
              {stack.map((s, index) => (
                <motion.div
                  key={s.name}
                  onClick={() => handleChangeAnalytics(s)}
                  role="button"
                  aria-label={s.name}
                  initial={{ opacity: 0, y: -50 }}
                  animate={isVisible ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 1, delay: index * 0.1 }}
                >
                  <div
                    className={`${
                      s.name !== activeStack.name
                        ? "text-[#838383] text-lg/[40px] sm:text-xl/[40px] md:text-[20px]/[40px] 3xl:text-2xl/[70px] hover:text-[#FBFF20] font-[400]"
                        : `text-[#FBFF20] italic text-[24px] md:text-[40px] font-[700] leading-[50px] mb-0 mt-4`
                    } lg:w-2/3 lg:w-full`}
                  >
                    {localizeContent(s, "name", locale)}
                  </div>
                  <div
                    className={`${
                      activeStack.name !== s.name
                        ? "bg-gradientGray text-data"
                        : ``
                    } text-base sm:text-lg 2xl:text-xl/[32px] text-white`}
                    role="button"
                    aria-label={s.name}
                  >
                    {activeStack.name === s.name && (
                      <motion.div
                        className="py-3 sm:w-[90%] text-[15px] md:text-[19px] leading-6 md:leading-7 font-[400] lg:min-h-[200px]"
                        id="#dynamicContent"
                        dangerouslySetInnerHTML={{
                          __html: localizeContent(s, "content", locale),
                        }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={isVisible ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                      />
                    )}
                    <div className="block lg:hidden">
                      {activeStack.name === s.name && (
                        <motion.div
                          className="sm:w-[90%] relative z-50 max-h-[280px] overflow-hidden"
                          initial={{ opacity: 0, y: 10 }}
                          animate={isVisible ? { opacity: 1, y: 0 } : {}}
                          transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                          <SourceFlowImage
                            src={activeStack.homepage_image ?? image ?? ""}
                            size="827x759"
                            alt="team"
                            className="w-full h-full"
                          />
                          <div className="absolute w-full bottom-0 p-4 md:pl-[68px] md:pt-[58px] md:pr-[37px] md:pb-[34px] h-full flex flex-col justify-between items-end">
                            <div className="mt-auto ">
                              <ExpandingButton
                                url={localizePath(
                                  `/specialisms/${activeStack.url_slug}`,
                                  locale,
                                )}
                                color="white"
                                bgColor="transparent"
                                hoverBGColor="white"
                                hoverColor="#1B0D34"
                              >
                                <CustomSourceFlowText
                                className="text-[16px] lg:text-xl "
                                  path={`dynamic1-learnmore.${path}`}
                                  title={
                                    locale === "de"
                                      ? "Lerne mehr"
                                      : "Learn more"
                                  }
                                  locale={locale}
                                />
                              </ExpandingButton>
                            </div>
                          </div>
                        </motion.div>
                      )}
                    </div>
                  </div>
                  <div className="sm:w-[90%]">
                    <div className="border-t border-gray-300 my-2" />
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="hidden relative lg:block py-10">
            <AnimatePresence>
              {activeStack && (
                <motion.div
                  className="h-full w-full absolute z-50"
                  key={activeStack.name}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <SourceFlowImage
                    src={activeStack.homepage_image ?? image ?? ""}
                    size="827x759"
                    alt="team"
                    className={`w-full h-full object-cover`}
                  />
                  <div className="absolute w-full bottom-0 p-4 md:pl-[68px] md:pt-[58px] md:pr-[37px] md:pb-[34px] h-full flex flex-col justify-between items-end">
                    <div className="mt-auto">
                      <ExpandingButton
                        url={localizePath(
                          `/specialisms/${activeStack.url_slug}`,
                          locale,
                        )}
                        color="white"
                        bgColor="transparent"
                        hoverBGColor="white"
                        hoverColor="#1B0D34"
                      >
                        <CustomSourceFlowText
                          path={`dynamic1-learnmore.${path}`}
                          title={locale === "de" ? "Lerne mehr" : "Learn more"}
                          locale={locale}
                        />
                      </ExpandingButton>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </section>
    </>
  );
}
