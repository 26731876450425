export default function localizeContent(obj, key, locale) {
  if (locale === "en" && key in obj) {
    return obj[key];
  }

  if (locale === "usa" && `${key}_en-us` in obj) {
    return obj[`${key}_en-us`];
  }

  if (locale === "usa" && `${key}_us` in obj) {
    return obj[`${key}_us`];
  }

  if (`${key}_${locale}` in obj) {
    return obj[`${key}_${locale}`];
  }

  if (key in obj) {
    return obj[key];
  }
}
